// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-ocular-src-templates-index-jsx": () => import("../node_modules/gatsby-theme-ocular/src/templates/index.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-ocular-src-templates-index-jsx" */),
  "component---node-modules-gatsby-theme-ocular-src-templates-doc-page-markdown-jsx": () => import("../node_modules/gatsby-theme-ocular/src/templates/doc-page-markdown.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-ocular-src-templates-doc-page-markdown-jsx" */),
  "component---node-modules-gatsby-theme-ocular-src-templates-search-jsx": () => import("../node_modules/gatsby-theme-ocular/src/templates/search.jsx" /* webpackChunkName: "component---node-modules-gatsby-theme-ocular-src-templates-search-jsx" */),
  "component---src-pages-geojson-editor-tsx": () => import("../src/pages/geojson-editor.tsx" /* webpackChunkName: "component---src-pages-geojson-editor-tsx" */)
}

